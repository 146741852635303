exports.components = {
  "component---src-pages-call-to-action-js": () => import("./../../../src/pages/callToAction.js" /* webpackChunkName: "component---src-pages-call-to-action-js" */),
  "component---src-pages-chrome-extension-js": () => import("./../../../src/pages/chromeExtension.js" /* webpackChunkName: "component---src-pages-chrome-extension-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-copyright-js": () => import("./../../../src/pages/copyright.js" /* webpackChunkName: "component---src-pages-copyright-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-demo-video-js": () => import("./../../../src/pages/demoVideo.js" /* webpackChunkName: "component---src-pages-demo-video-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-footer-js": () => import("./../../../src/pages/footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-frequently-asked-question-js": () => import("./../../../src/pages/frequentlyAskedQuestion.js" /* webpackChunkName: "component---src-pages-frequently-asked-question-js" */),
  "component---src-pages-header-js": () => import("./../../../src/pages/header.js" /* webpackChunkName: "component---src-pages-header-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-js": () => import("./../../../src/pages/main.js" /* webpackChunkName: "component---src-pages-main-js" */),
  "component---src-pages-offer-js": () => import("./../../../src/pages/offer.js" /* webpackChunkName: "component---src-pages-offer-js" */),
  "component---src-pages-paddle-button-js": () => import("./../../../src/pages/paddleButton.js" /* webpackChunkName: "component---src-pages-paddle-button-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refundPolicy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-refunds-js": () => import("./../../../src/pages/refunds.js" /* webpackChunkName: "component---src-pages-refunds-js" */),
  "component---src-pages-seo-jsx": () => import("./../../../src/pages/seo.jsx" /* webpackChunkName: "component---src-pages-seo-jsx" */),
  "component---src-pages-sub-hero-js": () => import("./../../../src/pages/subHero.js" /* webpackChunkName: "component---src-pages-sub-hero-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-tool-js": () => import("./../../../src/pages/tool.js" /* webpackChunkName: "component---src-pages-tool-js" */),
  "component---src-pages-who-for-js": () => import("./../../../src/pages/whoFor.js" /* webpackChunkName: "component---src-pages-who-for-js" */)
}

