import React, { useEffect } from "react";
import 'bulma/css/bulma.min.css';
import Header from "../pages/header";
import Footer from "../pages/footer";

const Layout = ({ children }) => {
    useEffect(() => {

        // Create a <noscript> element
        const noscriptElement = document.createElement('noscript');

        // Set the inner HTML of the <noscript> element
        noscriptElement.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TPM8DRG"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `;

        // Insert the <noscript> element as the first child of the body
        if (document.body.firstChild) {
            document.body.insertBefore(noscriptElement, document.body.firstChild);
        } else {
            // If there are no other elements in the body
            document.body.appendChild(noscriptElement);
        }

        const scriptConversionEvent = document.createElement('script');
        scriptConversionEvent.type = 'text/javascript';
        scriptConversionEvent.innerHTML = `function gtag_report_conversion(url, transactionId) { var callback = function () { if (typeof(url) != 'undefined') { window.location = url; } }; gtag('event', 'conversion', { 'send_to': 'AW-848321160/GcagCJv51uQYEIi1wZQD', 'transaction_id': transactionId, 'event_callback': callback }); return false; }`
        document.body.appendChild(scriptConversionEvent);


        const script = document.createElement('script');
        script.src = 'https://cdn.paddle.com/paddle/paddle.js';
        document.body.appendChild(script);

        script.onload = () => {
            const paddleScript = document.createElement('script');
            paddleScript.id = 'paddle-setup';
            paddleScript.type = 'text/javascript';

            if (process.env.GATSBY_CONTEXT === 'dev') {
                paddleScript.innerHTML = 'Paddle.Setup({ vendor: ' + process.env.GATSBY_DEV_PADDLE_VENDOR_ID + ', eventCallback: function(data) { if (data.event === "Checkout.Complete") { console.log(data.eventData); gtag_report_conversion(undefined, data.eventData.checkout.id) } } });';
                paddleScript.innerHTML += 'Paddle.Environment.set(\'sandbox\');'
            }
            else {
                paddleScript.innerHTML = 'Paddle.Setup({vendor: ' + process.env.GATSBY_PADDLE_VENDOR_ID + ', eventCallback: function(data) { if (data.event === "Checkout.Complete") { console.log(data.eventData); gtag_report_conversion(undefined, data.eventData.checkout.id) } } });';
            }

            document.body.appendChild(paddleScript);


            // const scriptConversionEvent = document.createElement('script');
            // scriptConversionEvent.type = 'text/javascript';
            // scriptConversionEvent.innerHTML = `function gtag_report_conversion(url, transactionId) { var callback = function () { if (typeof(url) != 'undefined') { window.location = url; } }; gtag('event', 'conversion', { 'send_to': 'AW-848321160/GcagCJv51uQYEIi1wZQD', 'transaction_id': transactionId, 'event_callback': callback }); return false; }`
            // document.body.appendChild(scriptConversionEvent);


            // const script = document.createElement('script');
            // script.src = 'https://cdn.paddle.com/paddle/v2/paddle.js';
            // document.body.appendChild(script);

            // script.onload = () => {
            //     const paddleScript = document.createElement('script');
            //     paddleScript.id = 'paddle-setup';
            //     paddleScript.type = 'text/javascript';

            //     if (process.env.GATSBY_CONTEXT === 'dev') {
            //         paddleScript.innerHTML = `
            //             Paddle.Environment.set("sandbox");
            //             Paddle.Setup({
            //                 token: '${process.env.GATSBY_DEV_PADDLE_BILLING_CLIENT_SIDE_TOKEN}',
            //                 eventCallback: function(data) {
            //                     console.log(data);
            //                     if (data.name === "checkout.complete") {
            //                         console.log(data);
            //                         console.log(data.data.id);
            //                         gtag_report_conversion(undefined, data.data.id)
            //                     }
            //                 }
            //             });
            //         `;
            //     }

            //     else {
            //         paddleScript.innerHTML = `
            //             Paddle.Setup({
            //                 token: '${process.env.GATSBY_PROD_PADDLE_BILLING_CLIENT_SIDE_TOKEN}',
            //                 eventCallback: function(data) {
            //                     console.log(data);
            //                     if (data.name === "checkout.complete") {
            //                         console.log(data);
            //                         console.log(data.data.id);
            //                         gtag_report_conversion(undefined, data.data.id)
            //                     }
            //                 }
            //             });
            //         `;
            //     }

            //     document.body.appendChild(paddleScript);
        }
    }, []);

    return (
        <div>
            <Header />
            {children}
            <Footer />
        </div>
    );
};

export default Layout;
